import React from "react";

const EmbedPage = () => {
    return (
        <>
            embed
        </>
    );
};

export default EmbedPage;
